<template>
  <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page="pages"
      :page-sizes="[30, 60, 90, 120]"
      :page-size="Size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
  </el-pagination>
</template>

<script>

export default {
  name: "myPagination",
  data() {
    return {
      url: '',
      Size: this.pageSize || 0,
      pages: this.currentPage || 10,
      total: 0,
    }
  },
  props: ['currentPage', 'pageSize', 'dataUrl', 'searchData'],
  created() {
    // console.log(this.searchData);
    this.getTableData()
  },
  methods: {
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.Size = val;
      this.pages = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.getTableData()
    },
    prevClick(val) {
      this.pages = val;
      this.getTableData()
    },
    nextClick(val) {
      this.pages = val;
      this.getTableData()
    },
    getTableData() {
      let _this = this;
      let url = _this.dataUrl + '?page=' + _this.pages + '&limit=' + _this.Size;
      var params = Object.keys(_this.searchData).map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(_this.searchData[key]);
      }).join("&");
      if (params) {
        url = url + '&' + params;
      }
      _this.axios.get(url)
          .then(function (res) {
            if (res.code == 200) {
              _this.total = res.count;
              _this.$emit('getData', res.data)
            } else {
              _this.$message.error(res.msg);
            }
          });

    },
  }

}
</script>

<style scoped>

</style>